import { ApiMethod, ClientResponse, enums } from "~/api";
import { checkIfOtpError, OtpError } from "~/api/errors/OtpError";
import { checkIfTokenError, TokenError } from "~/api/errors/TokenError";
import { INVITE_TYPE } from "~/api/interfaces";
import { createThunk } from "~/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { constants } from "./constants";
import {
  entityConfig as entityConfigActions,
  EntityConfigState,
  slice as entityConfigSlice,
} from "./entity-config";
import { flowControl, setTokenError } from "./flow-control";
import { prePopulateFields, setProfileValues } from "./registration";

export type OtpChannel = "sms" | "whatsapp";

type RequestOTPResponse = ApiMethod<"requestOTP">["otpInstance"];
type ValidateOTPResponse = ApiMethod<"validateOTP">;
type ConfirmLocalProfileResponse = ApiMethod<"confirmLocalProfile">;

// TODO: check if we can implement using this https://redux.js.org/usage/writing-logic-thunks#fetching-data-with-rtk-query
const api = {
  requestOTP(
    phoneNumber: string,
    channel?: OtpChannel
  ): ClientResponse<RequestOTPResponse> {
    return fetch(
      `/api/auth/request-otp?phoneNumber=${encodeURIComponent(
        phoneNumber
      )}&channel=${channel}`
    )
      .then((res) => res.json())
      .then(checkIfTokenError);
  },
  validateOtpCode(
    phoneNumber: string,
    instanceCode: number,
    otpCode: string
  ): ClientResponse<ValidateOTPResponse> {
    return fetch(
      `/api/auth/validate-otp?phoneNumber=${encodeURIComponent(
        phoneNumber
      )}&instanceCode=${instanceCode}&otpCode=${otpCode}`
    )
      .then((res) => res.json())
      .then(checkIfTokenError)
      .then(checkIfOtpError);
  },
  confirmLocalProfile(
    instanceCode: number,
    inviteType: INVITE_TYPE
  ): ClientResponse<ConfirmLocalProfileResponse> {
    return fetch(
      `/api/auth/confirm-local-profile?instanceCode=${instanceCode}&inviteType=${inviteType}`
    )
      .then((res) => res.json())
      .then(checkIfTokenError);
  },
};

interface AuthenticationState {
  authenticated: boolean;
  phoneNumber: string;
  otpInstanceCode: number;
  channel: OtpChannel;
  loading: "idle" | "pending" | "success" | "error" | "sending-otp";

  // First step is OTP Request
  otpRequestTimeStamp: number;
  otpRequestErrorMessage?: string;

  // Second step is OTP Validation
  otpValidationTimeStamp: number;
  otpValidationError?: {
    message: string;
    type: keyof typeof enums.OTP_VALIDATION_ERRORS;
  };

  hasActiveParkingBookingOnThisToken: boolean;
  hasValidInduction: boolean;
  maskNumber?: boolean;
}

const initialState: AuthenticationState = {
  authenticated: false,
  phoneNumber: constants.debug ? "+33666888117" : "",
  maskNumber: false,
  otpInstanceCode: 0,
  channel: "" as OtpChannel,

  loading: "idle",

  otpRequestTimeStamp: 0,
  otpRequestErrorMessage: undefined,

  otpValidationTimeStamp: 0,
  otpValidationError: undefined,

  hasActiveParkingBookingOnThisToken: false,
  hasValidInduction: false,
};

export const slice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    resetPhoneNumber(state): void {
      // Reset the states when phone number has changed
      Object.keys(initialState).forEach((key) => {
        if (key !== "phoneNumber") {
          state = merge(state, {
            [key]: initialState[key as keyof typeof initialState],
          });
        }
      });
    },
    setPhoneNumber(state, action: PayloadAction<string>): void {
      state.phoneNumber = action.payload;
      // Reset the states when phone number has changed
      Object.keys(initialState).forEach((key) => {
        if (key !== "phoneNumber") {
          state = merge(state, {
            [key]: initialState[key as keyof typeof initialState],
          });
        }
      });
    },
    setLoading(
      state,
      action: PayloadAction<AuthenticationState["loading"]>
    ): void {
      state.loading = action.payload;
    },
    setOtpInstanceCode(
      state,
      action: PayloadAction<{
        instanceCode: number;
        channel: AuthenticationState["channel"];
        phoneNumber: string;
      }>
    ): void {
      state.otpRequestTimeStamp = Date.now();
      state.otpInstanceCode = action.payload.instanceCode;
      state.channel = action.payload.channel;
      state.phoneNumber = action.payload.phoneNumber;
      state.otpRequestErrorMessage = undefined;
      state.loading = "idle";
    },
    setOtpRequestError(state, action: PayloadAction<string | undefined>): void {
      state.otpRequestErrorMessage = action.payload;
      state.loading = "error";
    },
    setOtpValidationError(
      state,
      action: PayloadAction<AuthenticationState["otpValidationError"]>
    ): void {
      if (action.payload === undefined) {
        state.otpValidationError = undefined;
        state.otpValidationTimeStamp = 0; // TODO: should this have current time as process has launched?
      } else {
        state.otpValidationError = action.payload;
        state.loading = "error";
      }
    },

    setValidOtpNumber(state): void {
      state.otpValidationTimeStamp = Date.now(); // TODO: should this be after validation or before?
      state.otpValidationError = undefined;
      state.loading = "success";
    },
    setActiveBookingParkingForToken(
      state,
      action: PayloadAction<boolean>
    ): void {
      state.hasActiveParkingBookingOnThisToken = action.payload;
    },
    setHasValidInduction(state, action: PayloadAction<boolean>): void {
      state.hasValidInduction = action.payload;
    },
    setAuthenticated(state) {
      state.authenticated = true;
    },
  },
  extraReducers: {
    [entityConfigSlice.actions.setEntityConfig.type]: (
      state,
      action: PayloadAction<EntityConfigState>
    ) => {
      const presetNumber = getPresetNumber(action.payload);
      state.maskNumber = Boolean(presetNumber);
      state.phoneNumber = presetNumber || "";
    },
    [entityConfigSlice.actions.setIsPhoneNumberChange.type]: (state) => {
      state.maskNumber = false;
      state.phoneNumber = "";
    },
    ["flow-control/setIsMyGlobalProfile"]: (state) => {
      state.authenticated = true;
    },
  },
});

const getPresetNumber = (state: EntityConfigState) => {
  const { isPostInitialRegistration, tokenPhoneNumber } =
    state?.postInitialRegistrationFlowAvailability ?? {};

  if (isPostInitialRegistration) {
    return tokenPhoneNumber ?? "";
  } else {
    return state?.inviteCreationFields?.invitePhoneNumber ?? "";
  }
};

export const { reducer } = slice;

// PUBLIC METHODS
export const { resetPhoneNumber, setAuthenticated } = slice.actions;

// PRIVATE METHODS
const {
  setLoading,
  setOtpInstanceCode,
  setOtpValidationError,
  setValidOtpNumber,
  setActiveBookingParkingForToken,
  setHasValidInduction,
} = slice.actions;

// TODO - Create a middleware with dispatcher for token errors

export const requestOTPInstance = (
  phoneNumber: string,
  channel: OtpChannel
) => {
  return createThunk(async function requestOTPInstanceThunk(dispatch) {
    dispatch(setLoading("sending-otp"));
    try {
      const instanceCode = await api.requestOTP(phoneNumber, channel);

      if (instanceCode?.error) {
        debugger;
        return; // TODO handle error
      }

      dispatch(setOtpInstanceCode({ instanceCode, channel, phoneNumber }));
    } catch (error) {
      if (error instanceof TokenError) {
        return dispatch(setTokenError(error.getClientError()));
      }

      dispatch(setOtpValidationError(error.message));
    } finally {
      dispatch(setLoading("idle"));
    }
  });
};

export const validateOtpCode = (
  phoneNumber: string,
  otpInstanceCode: number,
  otpCode: string
) => {
  return createThunk(async function validateOtpThunk(dispatch, getState) {
    try {
      dispatch(setLoading("pending"));
      dispatch(setOtpValidationError(undefined));

      const result = await api.validateOtpCode(
        phoneNumber,
        otpInstanceCode,
        otpCode
      );
      if (result.error) {
        debugger; // TODO check what triggers here and if it should be displayed
      }

      dispatch(setValidOtpNumber());
      dispatch(
        setActiveBookingParkingForToken(
          Boolean(result.hasActiveParkingBookingOnThisToken)
        )
      );

      if (result.hasValidInduction) {
        dispatch(
          flowControl.setAvailableSteps({
            induction: false,
          })
        );
      }

      if (result.shouldCreateNewProfile) {
        //this is a phone number change - we need to set all local state to go into a new profile create + archive existing flow
        dispatch(entityConfigActions.setIsPhoneNumberChange());
      }

      if (result.hasActiveParkingBookingOnThisToken) {
        dispatch(
          flowControl.setParking({
            enabled: true,
            accepted: true,
          })
        );
      }

      if (result.prePopulatedFieldValues) {
        dispatch(prePopulateFields(result.prePopulatedFieldValues));
      }

      const hasGlobalRegistration = Boolean(
        result.globalFirstName || result.globalLastName
      );
      const entityConfig = getState()?.entityConfig ?? {};
      const showProfileForm =
        !hasGlobalRegistration &&
        !entityConfig.postInitialRegistrationFlowAvailability
          ?.isPostInitialRegistration;
      dispatch(
        flowControl.setAvailableSteps({
          profile: showProfileForm,
        })
      );

      if (hasGlobalRegistration) {
        return dispatch(
          flowControl.setGlobalProfileConfirmation({
            globalProfileConfirmationVisible: true,

            globalFirstName: result.globalFirstName,
            globalLastName: result.globalLastName,
            hasExistingLocalProfile: result.hasExistingLocalProfile,

            shouldArchiveExisting: false,

            globalProfileConfirmed: false,
            localProfileConfirmed: false,
          })
        );
      } else {
        dispatch(setAuthenticated());
        return;
      }
    } catch (error) {
      if (error instanceof TokenError) {
        return dispatch(setTokenError(error.getClientError()));
      }

      if (error instanceof OtpError) {
        return dispatch(setOtpValidationError(error.getClientError()));
      }

      debugger; // TODO error handling : other error handling
    }
  });
};

export const confirmLocalProfile = (
  instanceCode: number,
  inviteType: INVITE_TYPE
) => {
  return createThunk(async function confirmLocalProfileThunk(dispatch) {
    const dispatchLoading = (loading: boolean) => {
      dispatch(
        flowControl.setGlobalProfileConfirmation({
          loading: loading ? "loading" : "idle",
        })
      );
    };
    dispatchLoading(true);
    try {
      const result = await api.confirmLocalProfile(instanceCode, inviteType);

      if (result.key !== enums.API_RESPONSE_KEYS.OPERATION_PROCESSED) {
        throw new Error("Something went wrong"); // TODO error handling: add generic error message
      }

      const { appStateUpdateAfterProfileConfirmation: nextAppState } = result;
      const optionalFieldsEnabled =
        nextAppState?.configData.optionalFieldsEnabled;
      const inductionEnabled =
        optionalFieldsEnabled?.inductionQuestions ||
        optionalFieldsEnabled?.inductionVideo;
      if (nextAppState?.configData) {
        dispatch(entityConfigActions.setEntityConfig(nextAppState.configData));
      }

      const { confirmedProfileData } = nextAppState || {};

      if (confirmedProfileData) {
        dispatch(setActiveBookingParkingForToken(false));

        dispatch(setHasValidInduction(confirmedProfileData.hasValidInduction));

        if (inductionEnabled) {
          dispatch(
            flowControl.setAvailableSteps({
              induction: !confirmedProfileData.hasValidInduction,
            })
          );
        }

        dispatch(
          setProfileValues(confirmedProfileData.prePopulatedFieldValues as any) // TODO types : improve typing
        );

        dispatchLoading(false);

        dispatch(
          flowControl.setGlobalProfileConfirmation({
            globalProfileConfirmationVisible: false,
            localProfileConfirmed: true,
          })
        );
        dispatch(setAuthenticated());
        return;
      }
      debugger; // TODO should anything be handled here? error or message?
    } catch (error) {
      dispatchLoading(false);

      if (error instanceof TokenError) {
        return dispatch(setTokenError(error.getClientError()));
      }
      dispatch(setOtpValidationError(error.message));
    }
  });
};
