import type { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { SnackbarProvider } from "notistack";
import NProgress from "nprogress";
import { FC, useEffect } from "react";
import "../styles/global.css";
import { createTheme } from "../theme";
import { createEmotionCache } from "../utils";
import {
  AppSettingsConsumer,
  AppSettingsProvider,
} from "./context-provider/app-settings";
import intlRichTextElements from "./intl-rich-text-elements/intl-rich-text-elements";
import type { NextPage } from "./next-page";

export type KenaiAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
  title?: string;
  gtm?: string;
};

const clientSideEmotionCache = createEmotionCache();

const KenaiApp: FC<KenaiAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { pathname, events } = useRouter();

  const primaryPalette = pageProps.primaryPalette;
  const getLayout = Component.getLayout ?? ((page) => page);

  const isMaintenance = pathname === "/maintenance";

  useEffect(() => {
    const handleStart = (url: string) => {
      if (process.env.NODE_ENV === "development") {
        console.log(`Loading: ${url}`);
      }
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    events.on("routeChangeStart", handleStart);
    events.on("routeChangeComplete", handleStop);
    events.on("routeChangeError", handleStop);

    return () => {
      events.off("routeChangeStart", handleStart);
      events.off("routeChangeComplete", handleStop);
      events.off("routeChangeError", handleStop);
    };
  }, [events]);

  if (isMaintenance) {
    return (
      <>
        <Head>
          <title>
            {props.title ? `${props.title} - ` : ""}Undergoing Maintenance
          </title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <Component {...pageProps} />
      </>
    );
  }

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          {props.title && <title>{props.title}</title>}
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        {props.gtm && (
          <Script
            id="gtm"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${props.gtm}');
          `,
            }}
          />
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider maxSnack={3}>
            <AppSettingsProvider>
              <AppSettingsConsumer>
                {({ settings }) => (
                  <ThemeProvider
                    theme={createTheme({
                      direction: settings.direction,
                      responsiveFontSizes: settings.responsiveFontSizes,
                      mode: "light",
                      primaryPalette: primaryPalette,
                    })}
                  >
                    <CssBaseline />
                    {getLayout(<Component {...pageProps} />)}
                  </ThemeProvider>
                )}
              </AppSettingsConsumer>
            </AppSettingsProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </CacheProvider>
      {pageProps.twPrimary && (
        <style jsx global>{`
          :root {
            --color-primary: ${pageProps.twPrimary};
          }
        `}</style>
      )}
    </>
  );
};

// const withKenaiApp = (WrappedComponent: any) => {
//   const Component = (props: KenaiAppProps) => {
//     const {
//       Component,
//       emotionCache = clientSideEmotionCache,
//       pageProps,
//     } = props;
//     const {
//       pathname,
//       events,
//       defaultLocale = "en",
//       locale = "en",
//     } = useRouter();

//     const primaryPalette = pageProps.primaryPalette;
//     const getLayout = Component.getLayout ?? ((page) => page);

//     return (
//       <IntlProvider
//         locale={locale}
//         defaultLocale={defaultLocale}
//         messages={pageProps.intlMessages || {}}
//         defaultRichTextElements={intlRichTextElements}
//       >
//         <h1>Hello WOrld</h1>
//         <WrappedComponent {...props} />;
//       </IntlProvider>
//     );
//   };

//   return Component;
// };

export { KenaiApp, intlRichTextElements };
