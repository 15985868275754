import { getIntl } from "~/lib/intl";
import { defineMessages } from "react-intl";
import { enums } from "..";

const messages = defineMessages({
  EXPIRED: {
    defaultMessage:
      "The one time pin that you have entered has expired - please go back and resubmit",
    id: "gE68rd",
  },
  INVALID_OTPCODE: {
    defaultMessage: "The one time pin you have entered is incorrect",
    id: "agTmer",
  },
  INVALID: {
    defaultMessage: "The one time pin you have entered is incorrect",
    id: "agTmer",
  },
});

export class OtpError extends Error {
  type: keyof typeof enums.OTP_VALIDATION_ERRORS;
  message: string = "";
  code: 400 | 500 = 500;
  constructor(type: keyof typeof enums.OTP_VALIDATION_ERRORS, locale?: string) {
    super(`Otp Error: ${type}`);
    this.type = type;
    this.code = this.getCode(type);

    if (locale) {
      // on the client we don't need to run this because the message comes from the server
      // we use the setMessage method instead which has the message in the response.error
      this.message = this.getMessage(type, locale);
    }
  }

  setMessage(message: string) {
    this.message = message;
    return this;
  }

  getClientError() {
    return {
      code: this.code,
      type: this.type as keyof typeof enums.OTP_VALIDATION_ERRORS,
      message: this.message,
    };
  }

  errorResponse() {
    return {
      key: "OTP_ERROR",
      type: this.type as keyof typeof enums.OTP_VALIDATION_ERRORS,
      error: this.message,
    };
  }

  private getCode(type: keyof typeof enums.OTP_VALIDATION_ERRORS) {
    switch (type) {
      case enums.OTP_VALIDATION_ERRORS.EXPIRED:
        return 400;
      case enums.OTP_VALIDATION_ERRORS.INVALID_OTPCODE:
      case enums.OTP_VALIDATION_ERRORS.INVALID:
        return 500;
      default:
        return 500;
    }
  }

  private getMessage(
    type: keyof typeof enums.OTP_VALIDATION_ERRORS,
    locale = "en"
  ) {
    const message = messages?.[type];
    if (!message) {
      debugger; // TODO check which types are missing
    }
    return getIntl(locale).formatMessage(message || messages.INVALID);
  }
}

export const checkIfOtpError = <R extends Record<any, any> | string | number>(
  response: R
): R | OtpError => {
  if (typeof response === "string" || typeof response === "number") {
    return response;
  }
  if (response.key !== "OTP_ERROR") {
    return response;
  }
  throw new OtpError(response.type).setMessage(response.error);
};
