"use strict";
exports.__esModule = true;
exports.createEventTiming = exports.eventTimingSchema = void 0;
var zod_1 = require("zod");
exports.eventTimingSchema = zod_1.z.object({
    timeSinceEpoch: zod_1.z.number(),
    timezoneOffset: zod_1.z.number(),
    timeZone: zod_1.z.string()
});
function createEventTiming() {
    return exports.eventTimingSchema.parse({
        timeSinceEpoch: Date.now(),
        timezoneOffset: new Date().getTimezoneOffset(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
}
exports.createEventTiming = createEventTiming;
