import {
  // createDebugger,
  objectArrayValuesToString,
} from "@kenai/utils";
import * as Sentry from "@sentry/nextjs";
import { merge, pick } from "lodash";
import slugify from "slugify";
import { z } from "zod";
import * as enums from "./enums";
import { OtpError } from "./errors/OtpError";
import { TokenError } from "./errors/TokenError";
import { getDependencyFields, getRootCondition } from "./filterProcessor";
import {
  CheckInField,
  FLOW_CONFIG_FIELD_CONDITIONS,
  interfaces as interfaceTypes,
  INVITE_TYPE,
  REGISTRATION_ENTITY,
} from "./interfaces";

export type interfaces = interfaceTypes;

export { enums };

// const debug = createDebugger("kenai-api");

type API_RESPONSE_KEYS = keyof typeof enums.API_RESPONSE_KEYS;

export type ServerResponse<Data = Record<any, any>, Key = any> = Promise<
  Partial<Data> & {
    key: Key | API_RESPONSE_KEYS;
    type?: keyof typeof enums.TOKEN_ERROR_TYPES;
    error?: Error | TokenError;
  }
>;

export type ClientResponse<Data = any, Error = any> = Promise<
  Partial<Data> & { error?: Error }
>;
export type BlockingAlert =
  | false
  | { blockFurtherProcessing?: boolean; headerText: string; bodyText: string };
type FunctionKeys<T> = {
  [K in keyof T]: T[K] extends (...args: any) => any ? K : never;
}[keyof T];
export type ApiMethod<K extends FunctionKeys<API>> = Awaited<
  ReturnType<API[K]>
>;

const logEndpoint = (name: string, endpoint: RequestInfo | URL, data: any) => {
  if (!process.env.DEBUG?.includes("kenai:api")) {
    return;
  }

  const clonedData = { ...data };

  if ("body" in clonedData && typeof clonedData.body === "string") {
    try {
      clonedData.body = JSON.parse(clonedData.body);
    } catch (error) {
      console.error("Failed to parse body", error);
    }
  }

  console.log(
    "\nKenai API \n • api: %s \n • endpoint: %s \n • init: %o\n---",
    name,
    endpoint,
    clonedData
  );
};

const logEndpointResponse = (name: string, response: any) => {
  if (process.env.DEBUG?.includes("kenai:api")) {
    console.log(
      "\nKenai API \n • api: %s \n • result: %o\n---",
      name,
      response
    );
  }
};

export default class API {
  name = "kenai-api";
  endpoint = "";
  locale = "en";
  constructor(endpoint = "", locale = "en") {
    this.endpoint = endpoint;
    this.locale = locale;
  }

  setEndpoint(endpoint: string) {
    this.endpoint = endpoint;
  }

  setOptions(options: { endpoint?: string; locale?: string }) {
    this.endpoint = options.endpoint || this.endpoint;
    this.locale = options.locale || this.locale;
  }

  fetcher = (
    name: string,
    input: Parameters<typeof fetch>[0],
    init: Parameters<typeof fetch>[1]
  ) => {
    logEndpoint(name, input, init);
    return fetch(input, init).then(async (res) => {
      const result = await res.json();
      logEndpointResponse(name, result);
      return result;
    });
  };

  /**
   * Returns the invite token based on the event id
   *
   * #### Endpoints :
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/ae0ba063dcf280232e458c0bdab383e499ea8882/lambdas/invitationOperations/EventBasedInviteProcessor/index.js#L137-L156)
   */
  getEventBasedInviteToken(eventToken: string): ServerResponse<{
    inviteToken?: string;
  }> {
    try {
      return this.fetcher(
        this.getEventBasedInviteToken.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.EVENT_BASED_INVITE_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eventToken: eventToken,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS
                .GET_EVENT_BASED_INVITE_TOKEN,
          }),
        }
      )
        .then((response) => this.checkIfTokenError(response))
        .catch((err) => {
          debugger;
          return err;
        });
    } catch (error) {
      debugger;
      console.error("getEventBasedInviteToken", error);
      throw error;
    }
  }
  /**
   *  Returns the invite token based on device id
   *
   * #### Endpoints :
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/9bcb689d9288069df93b15c972854f940bf78f8d/lambdas/invitationOperations/DeviceBasedRegistrationTokenProcessor/index.js#L154-L173)
   */
  getDeviceBasedInviteToken(deviceToken: string): ServerResponse<any> {
    try {
      return this.fetcher(
        this.getDeviceBasedInviteToken.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.DEVICE_BASED_INVITE_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            deviceToken: deviceToken,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS
                .GET_DEVICE_BASED_INVITE_TOKEN,
          }),
        }
      ).then((response) => this.checkIfTokenError(response, true));
    } catch (error) {
      debugger;
      console.error("getDeviceBasedInviteToken", error);
      throw error;
    }
  }

  /**
   * Retrieves the entity hierarchy config
   *
   * #### Endpoints :
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/015333dc7a0df115a0a134065a0d23838941bc61/lambdas/invitationOperations/InvitationRegistrationProcessor/index.js#L3678-L3699)
   */
  getEntityConfig(
    inviteToken: {
      value: string;
      type: INVITE_TYPE;
    },
    phase: 1 | 2 = 2
  ): ServerResponse<{
    configData: interfaces["REGISTRATION_ENTITY"];
    blockingPreviousSubmissionAlert?: any;
    deviceTokenBased?: boolean;
  }> {
    try {
      return this.fetcher(
        this.getEntityConfig.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken.value,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS.GET_ENTITY_CONFIG,
            phase,
          }),
        }
      )
        .then((response) => {
          if (response.key !== enums.API_RESPONSE_KEYS.OPERATION_PROCESSED) {
            return response;
          }

          return {
            ...response,
            configData: this.parseConfigData(
              response.configData,
              inviteToken.type
            ),
          };
        })
        .then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("getEntityConfig", error);
      throw error;
    }
  }

  /**
   * Retrieves the entity agreement data
   *
   * #### Endpoints :
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/015333dc7a0df115a0a134065a0d23838941bc61/lambdas/invitationOperations/InvitationRegistrationProcessor/index.js#L2401-L2441)
   */
  retrieveEntityAgreement(inviteToken: string): ServerResponse<{
    agreementDetails: interfaces["VISITOR_AGREEMENT"];
  }> {
    try {
      return this.fetcher(
        this.retrieveEntityAgreement.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS
                .RETRIEVE_ENTITY_AGREEMENT,
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("retrieveEntityAgreement", error);
      throw error;
    }
  }

  /**
   * Calls the lambda function that will return an OTP instance code and send an OTP to the user
   *
   * #### Endpoints :
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/015333dc7a0df115a0a134065a0d23838941bc61/lambdas/invitationOperations/InvitationRegistrationProcessor/index.js#L778-L818)
   * - [Lambda](https://github.com/Spookfish-ai/kenai-aws/blob/5dc20988c453e55c634d17312953396637bdcb62/lambdas/verificationOperations/RequestOTPCode/index.js)
   */
  requestOTP(
    inviteToken: string,
    phoneNumber: string,
    channel: "sms" | "whatsapp" = "sms"
  ): ServerResponse<{
    otpInstance?: number;
  }> {
    try {
      return this.fetcher(
        this.requestOTP.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            operation: enums.INVITATION_REGISTRATION_OPERATIONS.REQUEST_OTP,
            phoneNumber,
            channel,
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("requestOTP", error);
      throw error;
    }
  }

  /**
   * Calls the lambda function that will validate an OTP code
   *
   * #### Endpoints :
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/015333dc7a0df115a0a134065a0d23838941bc61/lambdas/invitationOperations/InvitationRegistrationProcessor/index.js#L931-L1164)
   */
  validateOTP(
    inviteToken: string,
    phoneNumber: string,
    instanceCode: string,
    otpCode: string
  ): ServerResponse<{
    body?: {
      key: "OTP_ERROR";
      error: "EXPIRED" | "INVALID_OTPCODE" | "INVALID";
    };
    shouldCreateNewProfile?: boolean;
    hasExistingLocalProfile?: boolean;
    hasActiveParkingBookingOnThisToken?: boolean;
    hasValidInduction?: boolean;
    globalFirstName?: string;
    globalLastName?: string;
    prePopulatedFieldValues?: Record<any, any>;
  }> {
    try {
      return this.fetcher(
        this.validateOTP.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            operation: enums.INVITATION_REGISTRATION_OPERATIONS.VALIDATE_OTP,
            phoneNumber,
            instanceCode: parseInt(instanceCode, 10),
            otpCode: parseInt(otpCode, 10),
          }),
        }
      )
        .then((response) => this.checkIfTokenError(response))
        .then((response) => this.checkIfOtpError(response));
    } catch (error) {
      debugger;
      console.error("validateOTP", error);
      throw error;
    }
  }

  validateFace(
    inviteToken: string,
    instanceCode: string,
    faceBase64: string
  ): ServerResponse<{
    validationResult: keyof typeof enums.API_FACE_VALIDATION_KEYS;
  }> {
    try {
      return this.fetcher(
        this.validateFace.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            instanceCode: parseInt(instanceCode, 10),
            operation: enums.INVITATION_REGISTRATION_OPERATIONS.VALIDATE_FACE,
            faceBase64,
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("validateFace", error);
      throw error;
    }
  }

  submitProfile({
    instanceCode,
    inviteToken,
    profileValues,
    flags,
    inviteOnlyFieldValues,
    parkingData,
    inductionData,
    checkInFieldValues,
    selectedHost,
    creationEventTiming,
  }: SUBMIT_PROFILE_DATA): ServerResponse<{
    submissionResult?: enums.PROFILE_SUBMISSION_RESULT;
    triggeredAlert?: {
      blockFurtherProcessing: boolean;
      headerText: string;
      bodyText: string;
    };
    accessPassDetails?: {
      accessPassCode: string;
      accessPassValidToTs: number;
      email: string;
      phoneNumber: string;
    };
  }> {
    try {
      if (profileValues && "faceData" in profileValues) {
        if (process.env.NODE_ENV === "development") {
          console.error("faceData should not be sent to the server");
        }
        delete (profileValues as any).faceData; // this is removed from the schema but this is a safety net
      }

      return this.fetcher(
        this.submitProfile.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            operation: enums.INVITATION_REGISTRATION_OPERATIONS.SUBMIT_PROFILE,
            instanceCode: Number(instanceCode.toString()), // ensure a number
            profileSubmission: {
              profileValues,
              flags,
              inviteOnlyFieldValues,
              ...(parkingData && {
                parkingData: objectArrayValuesToString(parkingData),
              }),
              ...(inductionData && { inductionData }),
              ...(checkInFieldValues && {
                checkInFieldValues:
                  objectArrayValuesToString(checkInFieldValues),
              }),
              creationEventTiming,
              selectedHost,
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("submitProfile", error);
      throw error;
    }
  }

  submitUpdatePostRegistrationCompletion({
    instanceCode,
    inviteToken,
    flags,
    parkingData,
    inductionData,
    checkInFieldValues,
    selectedHost,
  }: Omit<
    SUBMIT_PROFILE_DATA,
    "profileValues" | "inviteOnlyFieldValues" | "creationEventTiming"
  >): ServerResponse<{
    submissionResult?: enums.PROFILE_SUBMISSION_RESULT;
    triggeredAlert?: {
      blockFurtherProcessing: boolean;
      headerText: string;
      bodyText: string;
    };
    accessPassDetails?: {
      accessPassCode: string;
      accessPassValidToTs: number;
      email: string;
      phoneNumber: string;
    };
  }> {
    try {
      return this.fetcher(
        this.submitUpdatePostRegistrationCompletion.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken,
            instanceCode,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS
                .SUBMIT_UPDATE_POST_REGISTRATION_COMPLETION,
            profileSubmission: {
              ...(inductionData && { inductionData }),
              ...(parkingData && {
                parkingData: objectArrayValuesToString(parkingData),
              }),
              ...(checkInFieldValues && {
                checkInFieldValues:
                  objectArrayValuesToString(checkInFieldValues),
              }),
              selectedHost,
              flags,
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("submitUpdatePostRegistrationCompletion", error);
      throw error;
    }
  }

  /**
   * Confirms the local profile for recognized visitor
   *
   * #### Docs:
   * - [Kenai AWS](https://github.com/Spookfish-ai/kenai-aws/blob/015333dc7a0df115a0a134065a0d23838941bc61/lambdas/invitationOperations/InvitationRegistrationProcessor/index.js#L2262-L2341)
   */
  confirmLocalProfile(
    inviteToken: string,
    instanceCode: string,
    inviteType: INVITE_TYPE
  ): ServerResponse<{
    appStateUpdateAfterProfileConfirmation: {
      configData: interfaces["REGISTRATION_ENTITY"];
      confirmedProfileData: {
        hasValidInduction: boolean;
        prePopulatedFieldValues: Record<any, any>;
      };
    };
  }> {
    try {
      return this.fetcher(
        this.confirmLocalProfile.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            instanceCode: parseInt(instanceCode, 10),
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS.CONFIRM_LOCAL_PROFILE,
          }),
        }
      )
        .then((response) => {
          if (
            response.key !== enums.API_RESPONSE_KEYS.OPERATION_PROCESSED ||
            !response.appStateUpdateAfterProfileConfirmation?.configData
          ) {
            return response;
          }
          return merge(response, {
            appStateUpdateAfterProfileConfirmation: {
              configData: this.parseConfigData(response.configData, inviteType),
            },
          });
        })
        .then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("confirmLocalProfile", error);
      throw error;
    }
  }

  devArchiveProfileAndResetToken(inviteToken: string): ServerResponse<any> {
    try {
      return this.fetcher(
        this.devArchiveProfileAndResetToken.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken: inviteToken,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS.DEV_ARCHIVE_AND_RESET,
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("devArchiveProfileAndResetToken", error);
      throw error;
    }
  }

  requestSetupOTP(setupToken: string): ServerResponse<any> {
    try {
      return this.fetcher(
        this.requestSetupOTP.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            setupToken,
            operation: enums.INVITATION_SETUP_OPERATIONS.REQUEST_SETUP_OTP,
            requestContent: {},
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("requestSetupOTP", error);
      throw error;
    }
  }

  validateSetupOTP(
    setupToken: string,
    instanceCode: string,
    otpCode: string
  ): ServerResponse<any> {
    try {
      return this.fetcher(
        this.validateSetupOTP.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            setupToken,
            operation: enums.INVITATION_SETUP_OPERATIONS.VALIDATE_SETUP_OTP,
            requestContent: {
              instanceCode: parseInt(instanceCode, 10),
              otpCode: parseInt(otpCode, 10),
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("validateSetupOTP", error);
      throw error;
    }
  }

  searchSetupHost(
    setupToken: string,
    instanceCode: string,
    searchString: string,
    entityHierarchy: string
  ): ServerResponse<any> {
    try {
      return this.fetcher(
        this.searchSetupHost.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            setupToken,
            operation: enums.INVITATION_SETUP_OPERATIONS.SEARCH_SETUP_HOST,
            requestContent: {
              instanceCode: parseInt(instanceCode, 10),
              searchString: searchString,
              entityHierarchy: entityHierarchy,
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("searchSetupHost", error);
      throw error;
    }
  }

  getSetupAttendeeDetailsForLocation(
    setupToken: string,
    instanceCode: string,
    entityHierarchy: string
  ): ServerResponse<any> {
    try {
      return this.fetcher(
        this.getSetupAttendeeDetailsForLocation.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            setupToken,
            operation:
              enums.INVITATION_SETUP_OPERATIONS
                .GET_ATTENDEE_DETAILS_FOR_LOCATION,
            requestContent: {
              instanceCode: parseInt(instanceCode, 10),
              SelectedLocation: entityHierarchy,
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("getSetupAttendeeDetailsForLocation", error);
      throw error;
    }
  }

  submitSetupProcessingUpdate(
    setupToken: string,
    instanceCode: string,
    setupProcessingDetailsSubmission: object
  ): ServerResponse<any> {
    try {
      return this.fetcher(
        this.submitSetupProcessingUpdate.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            setupToken,
            operation:
              enums.INVITATION_SETUP_OPERATIONS.SUBMIT_PROCESSING_UPDATE,
            requestContent: {
              instanceCode: parseInt(instanceCode, 10),
              setupProcessingDetailsSubmission:
                setupProcessingDetailsSubmission,
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("submitSetupProcessingUpdate", error);
      throw error;
    }
  }

  submitResendAction(
    setupToken: string,
    instanceCode: string,
    email: string,
    resendAction: enums.SETUP_ATTENDEE_RESEND_ACTIONS
  ): ServerResponse<any> {
    try {
      let operation = "";
      if (resendAction === enums.SETUP_ATTENDEE_RESEND_ACTIONS.INVITE) {
        operation = enums.INVITATION_SETUP_OPERATIONS.SUBMIT_RESEND_INVITE;
      } else if (resendAction === enums.SETUP_ATTENDEE_RESEND_ACTIONS.PRE_REG) {
        operation = enums.INVITATION_SETUP_OPERATIONS.SUBMIT_RESEND_PREREG;
      }
      return this.fetcher(
        this.submitResendAction.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            setupToken,
            operation: operation,
            requestContent: {
              instanceCode: parseInt(instanceCode, 10),
              email: email,
            },
          }),
        }
      ).then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("submitResendAction", error);
      throw error;
    }
  }

  searchRegistrationHost(
    inviteToken: string,
    instanceCode: string,
    searchString: string
  ): ServerResponse<{
    searchResults: Array<{
      EntityHierarchy: string;
      uniqueAttributeValue: string;
      email: string;
      phone_number: string;
      name: string;
    }>;
  }> {
    try {
      return this.fetcher(
        this.searchRegistrationHost.name,
        `${this.endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteToken,
            operation:
              enums.INVITATION_REGISTRATION_OPERATIONS.SEARCH_REGISTRATION_HOST,
            instanceCode: parseInt(instanceCode, 10),
            hostSearchString: searchString,
          }),
        }
      )
        .then((response) => {
          if (response.key === "OPERATION_PROCESSED" && response.searchResult) {
            // TODO security : Based on this PR, we might change the `uniqueAttributeValue`: see https://github.com/Spookfish-ai/kenai-aws/pull/32
            response.searchResult = response.searchResult.map((result: any) =>
              pick(result, ["uniqueAttributeValue", "name"])
            );
          }
          return response;
        })
        .then((response) => this.checkIfTokenError(response));
    } catch (error) {
      debugger;
      console.error("searchRegistrationHost", error);
      throw error;
    }
  }

  async getInviteToken(query?: Record<string, any>) {
    let value = query.token;
    let type = "token" as INVITE_TYPE;
    if (value) {
      return {
        value,
        type,
      };
    }

    const eventToken = query.event;
    if (eventToken) {
      value =
        (await this.getEventBasedInviteToken(eventToken)).inviteToken || "";
      if (!value) {
        throw new Error("Invalid event token");
      }
      type = "event";
      return {
        value,
        type,
      };
    }

    const deviceToken = query.dt;
    if (deviceToken) {
      value =
        (await this.getDeviceBasedInviteToken(deviceToken)).inviteToken || "";
      if (!value) {
        throw new Error("Invalid device token");
      }
      type = "device";
      return {
        value,
        type,
      };
    }

    throw new Error("Invite token not found");
  }

  private checkIfTokenError(
    response: Awaited<ServerResponse>,
    deviceTokenBased = false
  ) {
    if (response.key !== enums.API_RESPONSE_KEYS.TOKEN_ERROR) {
      return response;
    }

    if (!response.type) {
      Sentry.captureMessage(
        `Token error: missing error "type" in ${JSON.stringify(response)}`
      );
      debugger;
      throw new Error("Token error response missing type");
    }

    throw new TokenError(response.type).getServerError({
      deviceBasedToken: deviceTokenBased,
      blockingAlert: response.blockingPreviousSubmissionAlert,
      locale: this.locale,
    });
  }

  private checkIfOtpError(response: Awaited<ServerResponse>) {
    const body = response?.body ?? {};

    if (body.key !== "OTP_ERROR") {
      return response;
    }

    if (!body.error) {
      Sentry.captureMessage(
        `Otp Error: missing error "type" in ${JSON.stringify(response)}`
      );
      debugger;
      throw new Error("Otp error type missing type");
    }

    throw new OtpError(body.error, this.locale);
  }

  private parseConfigData(
    configData: interfaces["REGISTRATION_ENTITY"] | undefined = undefined,
    inviteType?: INVITE_TYPE
  ) {
    try {
      if (!configData) {
        return configData;
      }

      const logo = configData.logoSrc || configData.logo;

      const emailOptions = {
        email: false,
        emailRequired: false,
      };

      if (inviteType === "event") {
        if ("eventEmailCapture" in configData.optionalFieldsEnabled) {
          emailOptions.email = Boolean(
            configData.optionalFieldsEnabled.eventEmailCapture
          );
        } else {
          emailOptions.email = true;
        }

        if ("eventEmailCaptureMandatory" in configData.optionalFieldsEnabled) {
          emailOptions.emailRequired = Boolean(
            configData.optionalFieldsEnabled.eventEmailCaptureMandatory
          );
        }
      } else if (inviteType === "device" || configData.sourceDeviceToken) {
        if ("deviceEmailCapture" in configData.optionalFieldsEnabled) {
          emailOptions.email = Boolean(
            configData.optionalFieldsEnabled.deviceEmailCapture
          );
        } else {
          emailOptions.email = true;
        }

        if ("deviceEmailCaptureMandatory" in configData.optionalFieldsEnabled) {
          emailOptions.emailRequired = Boolean(
            configData.optionalFieldsEnabled.deviceEmailCaptureMandatory
          );
        }
      }

      configData.optionalFieldsEnabled = {
        ...configData.optionalFieldsEnabled,
        ...emailOptions,
      };

      /**
       * ? INFO
       *
       * If we do translations of fields or manipulation of field data, like pre-registration
       * app where we need to replace the translated values, that can be done here
       */

      delete configData.logoSrc;

      const agreementIdentifier = slugify(configData.entityName);

      const nextConfig: Partial<REGISTRATION_ENTITY> = {
        logo,
        agreementIdentifier,
      };

      if (configData.checkInFieldDetails.checkInFieldsAvailable) {
        const captureFields = checkInFieldProcessor(
          configData.checkInFieldDetails.captureFields as CheckInField[], // false positive
          configData.checkInFieldDetails
            .fieldConditions as FLOW_CONFIG_FIELD_CONDITIONS // false positive
        );

        nextConfig.checkInFieldDetails = merge(nextConfig.checkInFieldDetails, {
          captureFields,
        });
      }

      return merge(configData, nextConfig);
    } catch (error) {
      debugger;
      console.error("parseConfigData", error);
      return configData;
    }
  }
}

const checkInFieldProcessor = (
  fields: CheckInField[],
  fieldConditions: FLOW_CONFIG_FIELD_CONDITIONS
) => {
  const fieldTransformers = (field: CheckInField) => {
    const _rootCondition = getRootCondition(fieldConditions as any, field.id);
    const _dependencyFields = getDependencyFields(_rootCondition);
    return {
      ...field,
      _type: field.inputSettings.inputType, // We ensure the field type is at the first level for better typechecking.
      _rootCondition,
      _dependencyFields,
    };
  };

  return fields.map(fieldTransformers);
};

const eventTiming = z.object({
  timeSinceEpoch: z.number(),
  timeZone: z.string(),
  timezoneOffset: z.number(),
});

export const submitProfileSchema = z.object({
  instanceCode: z.number(),
  inviteToken: z.string(),
  creationEventTiming: eventTiming,
  profileValues: z
    .object({
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      email: z.string().optional(),
      company: z.string().optional(),
      phoneNumber: z.string().optional(),
      personalIdentificationNr: z.string().optional(),
      ProfileId: z.string().optional(), // inProcessProfileId
      // faceData: z.string().optional(), // this is done during face verification and should not be submitted
    })
    .optional(),
  flags: z.object({
    submittedWithFace: z.boolean(),
    archiveExisting: z.boolean(),
  }),
  inviteOnlyFieldValues: z.record(z.string(), z.any()).optional(),
  parkingData: z.record(z.string(), z.any()).optional(), // todo
  inductionData: z
    .object({
      completionStatus: z.enum(["skipped", "completed"]),
      inductionType: z.enum(["preRegistration"]),
      version: z.number(),
      eventTiming: eventTiming.optional(),
    })
    .optional(),
  checkInFieldValues: z.record(z.string(), z.any()).optional(), // todo
  selectedHost: z.record(z.string(), z.any()).optional(),
});

export type SUBMIT_PROFILE_DATA = z.infer<typeof submitProfileSchema>;
