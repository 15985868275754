import { combineReducers } from "@reduxjs/toolkit";
import { reducer as entityConfigReducer } from "~/slices/entity-config";
import { reducer as authenticationReducer } from "~/slices/authentication";
import { reducer as registrationReducer } from "~/slices/registration";
import { reducer as flowControlReducer } from "~/slices/flow-control";

export const rootReducer = combineReducers({
  entityConfig: entityConfigReducer,
  authentication: authenticationReducer,
  registration: registrationReducer,
  flowControl: flowControlReducer,
});
