import type { Direction, Theme } from "@mui/material";
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { baseThemeOptions } from "./base-theme-options";
import { darkThemeOptions } from "./dark-theme-options";
import { lightThemeOptions, defaultPrimary } from "./light-theme-options";
import type { ThemeOptions } from "@mui/material";

export {
  baseThemeOptions,
  darkThemeOptions,
  lightThemeOptions,
  defaultPrimary,
};

export interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module "@mui/material/styles" {
  interface Palette {
    neutral?: Neutral;
  }

  interface PaletteOptions {
    neutral?: Neutral;
  }
}

interface BaseThemeConfig extends ThemeOptions {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: "light" | "dark";
}
interface MultiModeTheme extends BaseThemeConfig {
  primaryPalette?: {
    dark: Parameters<typeof darkThemeOptions>[0];
    light: Parameters<typeof lightThemeOptions>[0];
  };
}

interface SingleModeTheme extends BaseThemeConfig {
  primaryPalette?: Parameters<typeof darkThemeOptions>[0];
}

type ThemeConfig = MultiModeTheme | SingleModeTheme;

const isMultiMode = (config: ThemeConfig): config is MultiModeTheme => {
  const primaryPalette = config?.primaryPalette ?? ({} as Record<any, any>);
  const modeIsObject = (palette: any, mode: BaseThemeConfig["mode"]) => {
    return mode in palette && typeof palette[mode] === "object";
  };
  return (
    modeIsObject(primaryPalette, "dark") ||
    modeIsObject(primaryPalette, "light")
  );
};

export const createTheme = (config: ThemeConfig): Theme => {
  if (
    "mode" in config &&
    config.primaryPalette &&
    (!config.primaryPalette.dark || !config.primaryPalette.light)
  ) {
    throw new Error(
      "[createTheme]: When setting a `mode`, you must also add a primary palette for each mode."
    );
  }

  let mode = config.mode ?? "light";
  let primaryPalette = defaultPrimary;

  if (config.primaryPalette) {
    if (isMultiMode(config)) {
      const selectedPalette = config.primaryPalette?.[mode];
      if (!selectedPalette) {
        console.warn(
          "[createTheme]: No primary palette found for mode: ",
          mode
        );
      }
      primaryPalette = selectedPalette ?? defaultPrimary;
    } else {
      primaryPalette = config.primaryPalette || defaultPrimary;
    }
  }

  const selectedThemeOptions =
    mode === "dark"
      ? darkThemeOptions(primaryPalette)
      : lightThemeOptions(primaryPalette);

  let theme = createMuiTheme(
    baseThemeOptions(primaryPalette?.main ?? defaultPrimary.main),
    selectedThemeOptions,
    {
      direction: config.direction,
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

/** @deprecated - only used for name spacing a collection of declarations */
export type _namespace_theme = "theme";
