"use strict";
exports.__esModule = true;
exports.stringAvatar = exports.stringToColor = void 0;
function stringToColor(string) {
    var hash = 0;
    var i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = "#";
    for (i = 0; i < 3; i += 1) {
        var value = (hash >> (i * 8)) & 0xff;
        color += "00".concat(value.toString(16)).substring(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}
exports.stringToColor = stringToColor;
function stringAvatar(name) {
    var _a, _b, _c, _d;
    if (!name) {
        name = "";
    }
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: "".concat((_b = (_a = name.split(" ")[0]) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : "").concat((_d = (_c = name.split(" ")[1]) === null || _c === void 0 ? void 0 : _c[0]) !== null && _d !== void 0 ? _d : "")
    };
}
exports.stringAvatar = stringAvatar;
