import type {
  FieldConditionRoot,
  FieldConditionFilter,
} from "@kenai/utils.filter-processor";

export { default } from "@kenai/utils.filter-processor";

export const getRootCondition = (
  fieldConditions: Record<string, FieldConditionRoot>,
  fieldId: string
) => {
  return fieldConditions?.[fieldId] ?? undefined;
};

/**
 * Returns the dependency fields for a given root condition
 */
export const getDependencyFields = (rootCondition: FieldConditionRoot) => {
  if (!rootCondition) {
    return undefined;
  }
  const fields: string[] = [];

  const checkNestedFilters = (filter: FieldConditionFilter) => {
    if (filter.fieldId) {
      fields.push(filter.fieldId);
    }
    if (filter.filters) {
      filter.filters.forEach(checkNestedFilters);
    }
  };

  checkNestedFilters(rootCondition.rootFilter);

  return fields.filter(Boolean);
};
