"use strict";
exports.__esModule = true;
exports.objectArrayValuesToString = exports.arrayToString = void 0;
/**
 * Converts an array to a separated string.
 */
function arrayToString(value, separator) {
    if (separator === void 0) { separator = ";"; }
    if (Array.isArray(value)) {
        return value.join(separator);
    }
    return value;
}
exports.arrayToString = arrayToString;
/**
 * Converts all array's within a object to a separated string.
 */
function objectArrayValuesToString(object, separator) {
    if (separator === void 0) { separator = ";"; }
    var store = object;
    for (var key in object) {
        var value = object[key];
        if (Array.isArray(value)) {
            store[key] = arrayToString(value, separator);
            continue;
        }
        continue;
    }
    return store;
}
exports.objectArrayValuesToString = objectArrayValuesToString;
