// import { WSASERVICE_NOT_FOUND } from "constants";

export enum API_NAMES {
  INVITATION_OPERTATIONS = "invitationOperations",
}

export enum INVITATION_OPERATIONS {
  INVITATION_REGISTRATION_PROCESSOR = "invitationRegistrationProcessor",
  EVENT_BASED_INVITE_PROCESSOR = "eventBasedInviteProcessor",
  DEVICE_BASED_INVITE_PROCESSOR = "deviceBasedRegistrationTokenProcessor",
  INVITATION_SETUP_PROCESSOR = "invitationSetupProcessor",
}

export enum INVITATION_REGISTRATION_OPERATIONS {
  GET_EVENT_BASED_INVITE_TOKEN = "getEventBasedInviteToken",
  GET_DEVICE_BASED_INVITE_TOKEN = "getDeviceBasedInviteToken",
  GET_ENTITY_CONFIG = "getEntityConfig",
  RETRIEVE_ENTITY_AGREEMENT = "retrieveEntityAgreement",
  REQUEST_OTP = "requestOTP",
  VALIDATE_OTP = "validateOTP",
  VALIDATE_FACE = "validateFace",
  SUBMIT_PROFILE = "submitProfile",
  SUBMIT_UPDATE_POST_REGISTRATION_COMPLETION = "submitUpdatePostRegistrationCompletion",
  CONFIRM_LOCAL_PROFILE = "confirmLocalProfile",
  DEV_ARCHIVE_AND_RESET = "devArchiveProfileAndResetToken",
  SEARCH_REGISTRATION_HOST = "searchRegistrationHost",
}

export enum INVITATION_SETUP_OPERATIONS {
  REQUEST_SETUP_OTP = "requestSetupOtpCode",
  VALIDATE_SETUP_OTP = "validateSetupOTPCode",
  SEARCH_SETUP_HOST = "searchSetupHost",
  GET_ATTENDEE_DETAILS_FOR_LOCATION = "getAttendeeDetailsForLocation",
  SUBMIT_PROCESSING_UPDATE = "submitProcessingUpdate",
  SUBMIT_RESEND_INVITE = "submitResendInvite",
  SUBMIT_RESEND_PREREG = "submitPreReg",
}

export enum FACE_VALIDATION_ERRORS {
  FACE_NOT_DETECTED,
  MULTIPLE_FACES_IN_FRAME,
  FACE_MATCHED,
  FACE_MULTI_MATCHED,
  GENERIC_ERROR,
}

export enum POST_INITIAL_FLOW_SELECTION_STATE {
  NOT_APPLICABLE,
  NOT_SELECTED,
  INDUCTION,
  PARKING,
  CHECK_IN,
}

export enum FACE_CAPTURE_TYPE {
  CAMERA,
  UPLOAD,
  NONE,
}

export enum PROFILE_SUBMISSION_RESULT {
  PROFILE_ALREADY_EXISTS,
  PROFILE_SUBMITTED,
  GENERIC_ERROR,
}

export enum INVITATION_SETUP_EVENT_STATUS {
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
}

export enum SEARCH_SETUP_HOSTS_ERRORS {
  TECHNICAL_ERROR,
}

export enum SETUP_GET_ATTENDEE_ERRORS {
  TECHNICAL_ERROR,
}

export enum SETUP_CREATE_INVITES_ERRORS {
  TECHNICAL_ERROR,
}

export enum SETUP_RESEND_ACTION_ERRORS {
  TECHNICAL_ERROR,
}

export enum ATTENDEE_EMAIL_COMMANDS {
  DONT_SEND = "DONT_SEND",
  SEND = "SEND",
}

export enum SETUP_ATTENDEE_RESEND_ACTIONS {
  PRE_REG,
  INVITE,
}

export enum INVITE_EMAIL_DISTRIBUTION_TYPE {
  SEND_TO_ALL_VISITORS,
  SEND_TO_UNREGISTERED_VISITORS,
  DONT_SEND,
}

export const FIELD_CONDITION_COMPARISON_OPERATORS = {
  EQ: "EQ",
  NE: "NE",
  GT: "GT",
  LT: "LT",
  GE: "GE",
  LE: "LE",
  BT: "BT",
  CONTAINS: "CONTAINS",
  NOTCONTAINS: "NOTCONTAINS",
  ISPRESENT: "ISPRESENT",
  ISNOTPRESENT: "ISNOTPRESENT",
} as const;

export const FIELD_CONDITION_JOIN_OPERATORS = {
  AND: "AND",
  OR: "OR",
} as const;

export const API_RESPONSE_KEYS = {
  GENERIC_ERROR: "GENERIC_ERROR",
  OPERATION_PROCESSED: "OPERATION_PROCESSED",
  TOKEN_ERROR: "TOKEN_ERROR",
} as const;

export const API_FACE_VALIDATION_KEYS = {
  FACE_CAN_BE_PROCESSED: "FACE_CAN_BE_PROCESSED",
  FACE_NOT_DETECTED: "FACE_NOT_DETECTED",
  MULTIPLE_FACES_IN_FRAME: "MULTIPLE_FACES_IN_FRAME",
  FACE_MATCHED: "FACE_MATCHED",
  FACE_MULTI_MATCHED: "FACE_MULTI_MATCHED",
} as const;

export const API_OTP_TOKEN_RESPONSE_KEYS = {
  ...API_RESPONSE_KEYS,
  OTP_VERIFIED: "OTP_VERIFIED",
} as const;

export const TOKEN_ERROR_TYPES = {
  BLOCKED_BY_PREVIOUS: "BLOCKED_BY_PREVIOUS",
  PROCESSED: "PROCESSED",
  PROFILE_REMOVED: "PROFILE_REMOVED",
  EXPIRED: "EXPIRED",
  CANCELLED: "CANCELLED",
  GENERIC: "GENERIC",
} as const;

export const OTP_VALIDATION_ERRORS = {
  EXPIRED: "EXPIRED",
  INVALID_OTPCODE: "INVALID_OTPCODE",
  INVALID: "INVALID",
} as const;

export const INPUT_TYPES = {
  staticDropDownListSingleSelect: "staticDropDownListSingleSelect",
  staticDropDownListMultiSelect: "staticDropDownListMultiSelect",
  number: "number",
  text: "text",
  slider: "slider",
} as const;
