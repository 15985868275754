import { Typography } from "@mui/material";
import { IntlProvider } from "react-intl";

const intlRichTextElements: IntlProvider["props"]["defaultRichTextElements"] = {
  b: (chunks) => <Typography variant="button">{chunks}</Typography>,
  caption: (chunks) => <Typography variant="caption">{chunks}</Typography>,
  highlight: (chunks) => (
    <Typography color="primary" component="span">
      {chunks}
    </Typography>
  ),
};

export default intlRichTextElements;
