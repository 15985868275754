/* deepscan-disable UNUSED_VAR_ASSIGN */
import { enums } from "~/api";
import { createThunk, useSelector } from "~/store";
import { clientLogger, tuple } from "@kenai/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { useMemo } from "react";
import { constants } from "./constants";
import { EntityConfigState, slice as entityConfigSlice } from "./entity-config";

const inviteSteps = tuple(
  "agreement",
  "authentication",
  "profile",
  "parking",
  "induction",
  "checkin",
  "summary"
);

export type InviteSteps = (typeof inviteSteps)[number];
export interface FlowControlState {
  inviteCreationFieldsControl: {
    hasChangedPhoneNumber: boolean;
  };
  parking: {
    enabled: boolean;
    skipped: boolean | undefined;
    accepted: boolean;
  };
  faceData: {
    validationTimeStamp: number;
    validationSuccess: boolean;
    validationErrorMessage: string | undefined;
    validationErrorType: enums.FACE_VALIDATION_ERRORS | undefined;
    validationInProcess: boolean;
    faceDataProcessingEnabled: boolean;
    faceDataProcessingSkipped: boolean;
    faceCaptureType: enums.FACE_CAPTURE_TYPE;
  };
  inductionVideo: {
    completed: boolean;
    skipped: boolean | undefined;
    skipVisible: boolean;
  };
  inductionQuestions: {
    completed: boolean;
    skipped: boolean | undefined;
    skipVisible: boolean;
    showQuestions: boolean;
  };
  profileSubmission: {
    errorMessage: string | undefined;
    submissionResult: enums.PROFILE_SUBMISSION_RESULT | undefined;
    triggeredAlert?: {
      blockFurtherProcessing: boolean;
      headerText: string;
      bodyText: string;
    };
    accessPassDetails?: {
      accessPassCode: string;
      accessPassValidToTs: number;
      email: string;
      phoneNumber: string;
    };
  };
  globalProfileConfirmation: {
    globalProfileConfirmationVisible: boolean;
    shouldArchiveExisting: boolean;
    hasExistingLocalProfile: boolean;
    globalFirstName: string;
    globalLastName: string;
    hasGlobalProfile: boolean;
    localProfileConfirmed: boolean;
    globalProfileConfirmed: boolean;
    // isConfirmingLocalProfile: boolean;
    loading: "idle" | "loading";
    isGlobalProfile: boolean;
  };
  hostSearchRequest: {
    hostSearchTimeStamp: number;
    hostSearchSuccessfull: boolean;
    hostSearchErrorMessage: string | undefined;
    hostSearchErrorType: enums.SEARCH_SETUP_HOSTS_ERRORS | undefined;
    hostSearchInProcess: boolean;
    hostSearchSecondaryInProcess: boolean;
  };
  email: {
    emailSkipped: boolean;
  };
  tokenError?: {
    headerText?: string;
    message: string;
    code: number;
  };
  isUpdating: boolean;
  availableSteps: Record<InviteSteps, boolean>;
  currentStepIndex: number;
  isDeviceToken: boolean;
}

type PickFlowControl<key extends keyof FlowControlState> = Partial<
  Pick<FlowControlState, key>[key]
>;

const initialState: FlowControlState = {
  inviteCreationFieldsControl: {
    hasChangedPhoneNumber: false,
  },
  parking: {
    enabled: false,
    skipped: undefined,
    accepted: false,
  },
  faceData: {
    validationTimeStamp: 0,
    validationSuccess: false,
    validationErrorMessage: undefined,
    validationErrorType: undefined,
    validationInProcess: false,
    faceDataProcessingEnabled: false,
    faceDataProcessingSkipped: false,
    faceCaptureType: enums.FACE_CAPTURE_TYPE.NONE,
  },
  inductionVideo: {
    completed: false,
    skipped: undefined,
    skipVisible: false,
  },
  inductionQuestions: {
    completed: false,
    skipped: undefined,
    skipVisible: false,
    showQuestions: false,
  },
  profileSubmission: {
    errorMessage: "",
    submissionResult: undefined,
    triggeredAlert: undefined,
    accessPassDetails: undefined,
  },
  globalProfileConfirmation: {
    globalProfileConfirmationVisible: false,
    shouldArchiveExisting: false,
    hasExistingLocalProfile: false,
    globalFirstName: "",
    globalLastName: "",
    hasGlobalProfile: false,
    localProfileConfirmed: constants.debug,
    globalProfileConfirmed: false,
    loading: "idle",
    isGlobalProfile: false,
  },
  hostSearchRequest: {
    hostSearchTimeStamp: 0,
    hostSearchSuccessfull: false,
    hostSearchErrorMessage: undefined,
    hostSearchErrorType: undefined,
    hostSearchInProcess: false,
    hostSearchSecondaryInProcess: false,
  },
  email: {
    emailSkipped: false,
  },
  tokenError: undefined,
  availableSteps: {
    agreement: true,
    authentication: true,
    profile: true,
    parking: false,
    induction: false,
    checkin: false,
    summary: true,
  },
  isUpdating: false,
  currentStepIndex: 0,
  isDeviceToken: false,
};

export const slice = createSlice({
  name: "flow-control",
  initialState,
  reducers: {
    setFlowControlState(
      state: FlowControlState,
      action: PayloadAction<Partial<FlowControlState>>
    ) {
      state = merge(state, action.payload);
    },

    setInviteCreationFieldsControl(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"inviteCreationFieldsControl">>
    ) {
      state.inviteCreationFieldsControl = merge(
        state.inviteCreationFieldsControl,
        action.payload
      );
    },
    setParking(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"parking">>
    ) {
      state.parking = merge(state.parking, action.payload);
    },
    setFaceData(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"faceData">>
    ) {
      state.faceData = merge(state.faceData, action.payload);
    },
    setInductionVideo(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"inductionVideo">>
    ) {
      state.inductionVideo = merge(state.inductionVideo, action.payload);
    },
    setInductionQuestions(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"inductionQuestions">>
    ) {
      state.inductionQuestions = merge(
        state.inductionQuestions,
        action.payload
      );
    },
    setProfileSubmission(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"profileSubmission">>
    ) {
      state.profileSubmission = merge(state.profileSubmission, action.payload);
    },
    setGlobalProfileConfirmation(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"globalProfileConfirmation">>
    ) {
      state.globalProfileConfirmation = merge(
        state.globalProfileConfirmation,
        action.payload
      );

      const isDeviceToken = state.isDeviceToken;
      if (
        (action.payload.globalProfileConfirmed ||
          action.payload.localProfileConfirmed) &&
        !isDeviceToken
      ) {
        // We don't process screening if it is a confirmed global | local profile
        state.availableSteps.profile = false;
      }
    },
    setHostSearchRequest(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"hostSearchRequest">>
    ) {
      state.hostSearchRequest = merge(state.hostSearchRequest, action.payload);
    },
    setEmail(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"email">>
    ) {
      state.email = merge(state.email, action.payload);
    },

    setIsMyGlobalProfile(
      state: FlowControlState,
      action: PayloadAction<boolean>
    ) {
      const isMyGlobalProfile = action.payload;
      if (isMyGlobalProfile) {
        state.globalProfileConfirmation = merge(
          state.globalProfileConfirmation,
          {
            globalProfileConfirmationVisible: false,
            globalProfileConfirmed: true,
            shouldArchiveExisting: false,
            isGlobalProfile: true,
          }
        );
      } else {
        state.globalProfileConfirmation = merge(
          state.globalProfileConfirmation,
          {
            globalProfileConfirmationVisible: false,
            globalProfileConfirmed: true,
            localProfileConfirmed: false,
            shouldArchiveExisting: true,
            loading: "idle",
            globalFirstName: "",
            globalLastName: "",
            hasGlobalProfile: false,
            hasExistingLocalProfile: false,
            isGlobalProfile: false,
          }
        );
      }

      state.availableSteps.authentication = false;
    },
    setAvailableSteps(
      state: FlowControlState,
      action: PayloadAction<PickFlowControl<"availableSteps">>
    ) {
      state.availableSteps = merge(state.availableSteps, action.payload);
    },
    /**
     * This should only be used to jump directly to a section, but it
     * should be used with caution as it does not handle disabled steps
     */
    setActiveStep(state, action: PayloadAction<InviteSteps>): void {
      if (action.payload === "summary") {
        state.isUpdating = false;
      }
      if (process.env.NODE_ENV === "development") {
        console.log(
          "🔍️ step index",
          indexForStep(action.payload, state.availableSteps)
        );
      }
      state.currentStepIndex = indexForStep(
        action.payload,
        state.availableSteps
      );
    },
    nextStep(state) {
      state.currentStepIndex = nextActiveStep(
        state.currentStepIndex,
        state.availableSteps
      );
    },
    setTokenError(
      state,
      action: PayloadAction<FlowControlState["tokenError"]>
    ) {
      state.tokenError = action.payload;
    },
    setIsUpdating(state, action: PayloadAction<boolean>) {
      state.isUpdating = action.payload;
    },
  },
  extraReducers: {
    [entityConfigSlice.actions.setEntityConfig.type]: (
      state,
      action: PayloadAction<EntityConfigState>
    ) => {
      const entityConfig = action.payload;

      const isDeviceToken = Boolean(entityConfig.sourceDeviceToken);
      state.isDeviceToken = isDeviceToken;

      state.availableSteps = merge(
        state.availableSteps,
        getAvailableStepsForEntityConfig(entityConfig)
      );
    },
    ["authentication/setAuthenticated"]: (state) => {
      state.availableSteps.authentication = false;
    },
    [entityConfigSlice.actions.setIsPhoneNumberChange.type]: (state) => {
      state.globalProfileConfirmation.shouldArchiveExisting = true;
    },
  },
});

const getAvailableStepsForEntityConfig = (entityConfig: EntityConfigState) => {
  const availableSteps: Partial<FlowControlState["availableSteps"]> = {};

  const { optionalFieldsEnabled } = entityConfig;

  const induction =
    optionalFieldsEnabled.inductionQuestions ||
    optionalFieldsEnabled.inductionVideo;

  const parking = entityConfig.parkingDetails.parkingAvailable;
  const checkin = entityConfig.checkInFieldDetails.checkInFieldsAvailable;

  availableSteps.induction = induction;
  availableSteps.parking = parking;
  availableSteps.checkin = checkin;

  const isDeviceToken = Boolean(entityConfig.sourceDeviceToken);
  if (isDeviceToken) {
    availableSteps.profile = true;
  }

  return availableSteps;
};

export const { reducer } = slice;

// PUBLIC METHODS
export const flowControl = slice.actions;
export const { setTokenError, setActiveStep, nextStep, setIsUpdating } =
  slice.actions;

const nextActiveStep = (
  currentStep: number,
  availableSteps: FlowControlState["availableSteps"]
): number => {
  const nextStepIndex = currentStep + 1;
  const availableStepsList = filterSteps(availableSteps);
  if (nextStepIndex > availableStepsList.length) {
    debugger;
    throw new Error(`Could not find the next step`);
  }

  return nextStepIndex;
};

const filterSteps = (availableSteps: FlowControlState["availableSteps"]) => {
  return inviteSteps.filter((step) => availableSteps[step]);
};

const indexForStep = (
  nextStepName: InviteSteps,
  availableSteps: FlowControlState["availableSteps"]
): number => {
  return filterSteps(availableSteps).findIndex(
    (_step) => _step === nextStepName
  );
};

let prevList = "";

export const useActiveSteps = () => {
  const availableSteps = useSelector(
    (state) => state.flowControl.availableSteps
  );
  const currentStepIndex = useSelector(
    (state) => state.flowControl.currentStepIndex
  );

  const availableStepsList = useMemo(() => {
    const list = filterSteps(availableSteps);
    if (
      process.env.NODE_ENV === "development" &&
      prevList !== list.toString()
    ) {
      prevList = list.toString();
      clientLogger.log("🔍️ enabled steps %o", list);
    }
    return list;
  }, [availableSteps]);

  return {
    /** Object with the step enablement */
    availableSteps,
    /** A sorted list of enabled steps */
    availableStepsList,
    /** The current active step index */
    currentStepIndex,
    /** The current active step label */
    currentStep: availableStepsList[currentStepIndex],
  };
};

export const confirmGlobalProfile = (
  isMyGlobalProfile: boolean,
  entityConfig: EntityConfigState
) => {
  return createThunk(async (dispatch) => {
    dispatch(
      flowControl.setAvailableSteps(
        getAvailableStepsForEntityConfig(entityConfig)
      )
    );

    dispatch(flowControl.setIsMyGlobalProfile(isMyGlobalProfile));

    const { isPostInitialRegistration } =
      entityConfig.postInitialRegistrationFlowAvailability ?? {};
    if (!isPostInitialRegistration) {
      dispatch(flowControl.setAvailableSteps({ profile: true }));
    }
  });
};
