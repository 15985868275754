import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { ThunkAction } from "redux-thunk";
import {
  configureStore,
  createImmutableStateInvariantMiddleware,
  PreloadedState,
} from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import { rootReducer } from "./root-reducer";
import logger from "redux-logger";

import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { ReducerState } from "react";

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: [],
});

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      // TODO add sentry logging here
    }

    return next(action);
  };

const showDevTools =
  process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true" ||
  process.env.NODE_ENV === "development";

export const createStore = (preloadedState: PreloadState = undefined) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(rtkQueryErrorLogger)
        .concat(logger)
        .concat(immutableInvariantMiddleware);
    },
    preloadedState,
    devTools: showDevTools,
  });
};

export type PreloadState = PreloadedState<ReducerState<typeof rootReducer>>;

type Store = ReturnType<typeof createStore>;

export type RootState = ReturnType<Store["getState"]>;

export type AppDispatch = Store["dispatch"];

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

/**
 * We use this when we create a middleware thunk, because dispatch wants a type.
 * @see https://github.com/reduxjs/redux-toolkit/issues/321
 */
export const createThunk = (thunk: AppThunk, type?: string) => {
  const thunkWithType = thunk as AppThunk & { type: string };
  thunkWithType.type = type ?? "middleware-thunk";
  return thunkWithType;
};
