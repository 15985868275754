"use strict";
exports.__esModule = true;
exports.copyToClipboard = void 0;
function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
    }
    catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        return Promise.reject(false);
    }
    document.body.removeChild(textArea);
    return Promise.resolve(true);
}
function copyToClipboard(text) {
    if (!navigator) {
        return Promise.reject(false);
    }
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text);
    }
    return navigator.clipboard.writeText(text).then(function () {
        console.log("Async: Copying to clipboard was successful!");
        return Promise.resolve(true);
    }, function (err) {
        console.error("Async: Could not copy text: ", err);
        return Promise.reject(false);
    });
}
exports.copyToClipboard = copyToClipboard;
