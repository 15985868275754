"use strict";
exports.__esModule = true;
function fetchAPI() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return fetch.apply(void 0, args).then(function (res) { return res.json(); })
        .then(function (res) { return res; });
}
exports["default"] = fetchAPI;
