"use strict";
exports.__esModule = true;
exports.tuple = void 0;
var tuple = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args;
};
exports.tuple = tuple;
