import { GTM_ID, pageview } from "~/lib/gtm";
import { createStore, PreloadState } from "~/store";
import { intlRichTextElements, KenaiApp } from "@kenai/core";
import { NextWebVitalsMetric } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Provider as ReduxProvider } from "react-redux";
import "../styles/global.css";

if (typeof window !== "undefined") {
  // expose store when run in Cypress
  if ((window as any).Cypress) {
    (window as any).store = createStore();
  }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log(metric);
}

const parseJSON = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error(error);
    return value;
  }
};

const InvitesApp: FC<any> = (props) => {
  const pageProps = props.pageProps ?? {};
  const { defaultLocale = "en", locale = "en", events } = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  const preloadState: PreloadState = {};

  if (pageProps.entityConfig && typeof pageProps.entityConfig === "string") {
    preloadState.entityConfig = parseJSON(pageProps.entityConfig);
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={pageProps.intlMessages || {}}
      defaultRichTextElements={intlRichTextElements}
    >
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ReduxProvider store={createStore(preloadState)}>
        <KenaiApp {...props} appTitle="Invites - Kenai" gtm={GTM_ID} />
      </ReduxProvider>
    </IntlProvider>
  );
};

export default InvitesApp;
