export const GTM_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;

const warn = (...args: string[]) => {
  if (process.env.NODE_ENV === "development") {
    console.warn(...args);
  }
};

export const pageview = (url: string) => {
  if (typeof window === "undefined") {
    warn("GTM pageview works only on client side.");
    return;
  }
  (window as any)?.dataLayer?.push({
    event: "pageview",
    page: url,
  });
};

export const event = (event: string) => {
  if (typeof window === "undefined") {
    warn("GTM events works only on client side.");
    return;
  }
  (window as any)?.dataLayer?.push({
    event,
  });
};
