import { zodInitialValue } from "~/utils/zodIntialValue";
import { eventTimingSchema } from "@kenai/utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { merge, omit, pick } from "lodash";
import { z } from "zod";
import { slice as flowControlSlice } from "./flow-control";

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);

export const registrationSchema = z.object({
  creationEventTiming: eventTimingSchema as any, // TODO zod - fix miss matching types between library and app
  checkInFieldValues: z.record(z.string(), literalSchema).optional(),
  flags: z
    .object({
      archiveExisting: z.boolean().optional(),
      submittedWithFace: z.boolean(),
    })
    .optional(),
  inductionData: z
    .object({
      completionStatus: z.enum(["completed", "skipped"]),
      version: z.number(),
      questions: z.record(z.string(), z.string()).optional(),
    })
    .optional(),
  parkingData: z
    .object({
      parkingLicensePlate: z.string(),
      parkingVehicleMake: z.string(),
      parkingVehicleModel: z.string(),
      preferredParkingClassification: z.string(),
    })
    .optional(),
  inviteOnlyFieldValues: z
    .object({
      dietaryRequirements: z.string(),
    })
    .optional(),
  profileValues: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
      company: z.string(),
      personalIdentificationNr: z.string(),
      faceData: z.string(),
    })
    .optional(),
  selectedHost: z
    .object({
      name: z.string(),
      uniqueAttributeValue: z.string(),
    })
    .optional(),
});

export type RegistrationState = z.infer<typeof registrationSchema>;

type PickValueType<K extends keyof RegistrationState> = RegistrationState[K];
type StaticFieldPayload<K extends keyof RegistrationState> = PayloadAction<
  PickValueType<K>
>;

const initialState = zodInitialValue(registrationSchema);

const slice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setProfileValues: (state, action: StaticFieldPayload<"profileValues">) => {
      if (action.payload) {
        state.profileValues = merge(state.profileValues || {}, action.payload);
      } else {
        state.profileValues = undefined;
      }
    },
    setFaceData: (state, action: PayloadAction<string>) => {
      if (state.profileValues) {
        state.profileValues.faceData = action.payload;
      }
    },
    setCheckInFieldValues: (
      state,
      action: StaticFieldPayload<"checkInFieldValues">
    ) => {
      if (action.payload) {
        state.checkInFieldValues = action.payload;
      } else {
        state.checkInFieldValues = undefined;
      }
    },
    setInviteOnlyFieldValues: (
      state,
      action: StaticFieldPayload<"inviteOnlyFieldValues">
    ) => {
      if (action.payload) {
        state.inviteOnlyFieldValues = merge(
          state.inviteOnlyFieldValues || {},
          action.payload
        );
      } else {
        state.inviteOnlyFieldValues = undefined;
      }
    },
    setSelectedHost: (state, action: StaticFieldPayload<"selectedHost">) => {
      if (action.payload) {
        state.selectedHost = action.payload;
      } else {
        state.selectedHost = undefined;
      }
    },
    setParkingData: (state, action: StaticFieldPayload<"parkingData">) => {
      if (action.payload) {
        state.parkingData = merge(state.parkingData || {}, action.payload);
      } else {
        state.parkingData = undefined;
      }
    },
    setInductionData: (state, action: StaticFieldPayload<"inductionData">) => {
      if (action.payload) {
        state.inductionData = merge(state.inductionData || {}, action.payload);
      } else {
        state.inductionData = undefined;
      }
    },
    setFlags: (state, action: StaticFieldPayload<"flags">) => {
      if (action.payload) {
        state.flags = merge(state.flags || {}, action.payload);
      } else {
        state.flags = undefined;
      }
    },
    setCreationEventTiming: (
      state,
      action: StaticFieldPayload<"creationEventTiming">
    ) => {
      if (action.payload) {
        state.creationEventTiming = state.creationEventTiming || {};
        state.creationEventTiming = merge(
          state.creationEventTiming,
          action.payload
        );
      } else {
        state.creationEventTiming = undefined;
      }
    },
    prePopulateFields: (state, action: PayloadAction<Record<any, any>>) => {
      const { profileValues, parkingData, checkInFieldValues } =
        new PrePopulateFields(action.payload);

      state.profileValues = profileValues;
      state.parkingData = parkingData;
      state.checkInFieldValues = checkInFieldValues;
    },
  },
  extraReducers: {
    // [flowControlSlice?.actions?.setGlobalProfileConfirmation?.type]: (
    //   state,
    //   action
    // ) => {
    //   // if (action.payload.globalFirstName) {
    //   //   state.firstName = action.payload.globalFirstName;
    //   // }
    //   // if (action.payload.globalLastName) {
    //   //   state.lastName = action.payload.globalLastName;
    //   // }
    //   return state;
    // },
    [flowControlSlice?.actions.setIsMyGlobalProfile.type]: (
      state,
      action: PayloadAction<Boolean>
    ) => {
      const isMyGlobalProfile = action.payload;
      if (!isMyGlobalProfile) {
        if (!state.profileValues) {
          state.profileValues = {
            firstName: "",
            lastName: "",
            personalIdentificationNr: "",
            email: "",
            company: "",
            faceData: "",
          };
        } else {
          state.profileValues.firstName = "";
          state.profileValues.lastName = "";
        }
      }
      return state;
    },
  },
});

class PrePopulateFields {
  parkingData: RegistrationState["parkingData"];
  profileValues: RegistrationState["profileValues"];
  checkInFieldValues: RegistrationState["checkInFieldValues"];
  constructor(public payload: Record<any, any>) {
    this.profileValues = pick(payload, [
      "firstName",
      "lastName",
      "email",
      "company",
      "personalIdentificationNr",
      "faceData",
    ]);
    this.parkingData = pick(payload, [
      "parkingLicensePlate",
      "parkingVehicleMake",
      "parkingVehicleModel",
      "preferredParkingClassification",
    ]);

    this.checkInFieldValues = omit(payload, [
      ...Object.keys(this.profileValues),
      ...Object.keys(this.parkingData),
    ]);
  }
}

export const { reducer } = slice;

export const {
  setFaceData,
  prePopulateFields,
  setProfileValues,
  setCheckInFieldValues,
  setInviteOnlyFieldValues,
  setSelectedHost,
  setParkingData,
  setInductionData,
  setFlags,
  setCreationEventTiming,
} = slice.actions;
