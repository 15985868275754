import { createIntl, createIntlCache } from "react-intl"; // This is optional but highly recommended
import { IntlShape } from "react-intl";

const intl = {
  en: createIntl(
    {
      locale: "en",
      messages: {},
    },
    createIntlCache()
  ),
  fr: createIntl(
    {
      locale: "fr",
      messages: {},
    },
    createIntlCache()
  ),
  de: createIntl(
    {
      locale: "de",
      messages: {},
    },
    createIntlCache()
  ),
};

export const getIntl = (locale = "en"): IntlShape => {
  return intl[locale as keyof typeof intl] || intl.en;
};

export default intl;
