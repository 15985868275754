import { createContext, useContext, useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";

export interface AppSettings {
  direction?: "ltr" | "rtl";
  responsiveFontSizes?: boolean;
  theme: "light" | "dark";
}

interface ContextValue {
  settings: AppSettings;
  saveSettings: (update: AppSettings) => void;
}

interface ProviderProps {
  children?: ReactNode;
}

const initialSettings: AppSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: "light",
};

const restoreSettings = (): AppSettings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: "ltr",
        responsiveFontSizes: true,
        theme: window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light",
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

const storeSettings = (settings: AppSettings): void => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const AppSettingsContext = createContext<ContextValue>({
  settings: initialSettings,
  saveSettings: () => {},
});

export const AppSettingsProvider: FC<ProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<AppSettings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: AppSettings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <AppSettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

AppSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AppSettingsConsumer = AppSettingsContext.Consumer;

export const useAppSettings = (): ContextValue => {
  return useContext(AppSettingsContext);
};
