"use strict";
exports.__esModule = true;
exports.convertBase64 = void 0;
var convertBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
            resolve(fileReader.result);
        };
        fileReader.onerror = function (error) {
            reject(error);
        };
    });
};
exports.convertBase64 = convertBase64;
