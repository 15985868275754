import { getIntl } from "~/lib/intl";
import { defineMessage, defineMessages, MessageDescriptor } from "react-intl";
import { BlockingAlert, enums } from "..";

const expiredMessages = {
  device: defineMessages({
    headerText: {
      defaultMessage: "Rescan QR code",
      id: "VwxgT8",
    },
    message: {
      defaultMessage:
        "This process has expired. Please rescan the QR code on the iPad to restart the process.",
      id: "wEcvol",
    },
  }),
  token: defineMessage({
    defaultMessage:
      "This invite has expired as it is passed the scheduled start time of the invite.",
    id: "a2wcoM",
  }),
};

const messages = defineMessages({
  [enums.TOKEN_ERROR_TYPES.PROCESSED]: {
    defaultMessage:
      "Thank you for completing your registration, we look forward to seeing you.",
    id: "cpNoeg",
  },
  [enums.TOKEN_ERROR_TYPES.PROFILE_REMOVED]: {
    defaultMessage: `The profile previously created with this invite is not available any more and this invite cannot be used further. For example - You've signed in on site and then opted to be forgotten on the final screen.`,
    id: "uft2RM",
  },
  [enums.TOKEN_ERROR_TYPES.CANCELLED]: {
    defaultMessage: "This has been cancelled by the organizer.",
    id: "n7XVzC",
  },
  [enums.TOKEN_ERROR_TYPES.GENERIC]: {
    defaultMessage:
      "There is an issue processing your request. The invite may have expired or might have been cancelled by your host. Please close this page and try again by opening the pre-registration link in the email that was sent to you. If the problem persists please contact your host or email help@kenai.co.za",
    id: "YyOVXY",
  },
}) as Record<keyof typeof enums.TOKEN_ERROR_TYPES, MessageDescriptor>;

export class TokenError extends Error {
  type: keyof typeof enums.TOKEN_ERROR_TYPES;
  code: 400 | 401 | 500 = 500;
  options: {
    deviceBasedToken: boolean;
    blockingAlert: BlockingAlert;
    locale: string;
  } = {} as any;
  headerText: string | undefined;

  constructor(type: keyof typeof enums.TOKEN_ERROR_TYPES, message?: string) {
    super(`Token Error: ${type}`);
    this.message = message || `Token Error: ${type}`;
    this.name = "TokenError";
    this.type = type;
    this.code = this.getCode(type);
  }

  getServerError(options: typeof this.options) {
    this.options = options;
    this.setTexts(this.type, this.options);
    return this;
  }

  getClientError() {
    return {
      code: this.code,
      message: this.message,
      ...(this.options.blockingAlert && {
        headerText: this.options.blockingAlert.headerText,
      }),
      ...(this.headerText && { headerText: this.headerText }),
    };
  }

  errorResponse() {
    return {
      key: enums.API_RESPONSE_KEYS.TOKEN_ERROR,
      type: this.type,
      error: this.message,
    };
  }

  private getCode(type: keyof typeof enums.TOKEN_ERROR_TYPES) {
    switch (type) {
      case enums.TOKEN_ERROR_TYPES.CANCELLED:
      case enums.TOKEN_ERROR_TYPES.EXPIRED:
      case enums.TOKEN_ERROR_TYPES.PROFILE_REMOVED:
        return 400;
      case enums.TOKEN_ERROR_TYPES.GENERIC:
      case enums.TOKEN_ERROR_TYPES.BLOCKED_BY_PREVIOUS:
        return 500;
      case enums.TOKEN_ERROR_TYPES.PROCESSED:
        return 401;
      default:
        return 500;
    }
  }

  private setTexts(type: typeof this.type, options: typeof this.options) {
    const intl = getIntl(options.locale);
    if (
      type === enums.TOKEN_ERROR_TYPES.BLOCKED_BY_PREVIOUS &&
      options.blockingAlert
    ) {
      return options.blockingAlert.bodyText;
    }

    if (type === enums.TOKEN_ERROR_TYPES.EXPIRED) {
      if (options.deviceBasedToken) {
        const texts = expiredMessages.device;
        this.headerText = intl.formatMessage(texts.headerText);
        this.message = intl.formatMessage(texts.message);
        return;
      } else {
        this.message = intl.formatMessage(expiredMessages.token);
        return;
      }
    }
    this.message = intl.formatMessage(
      messages[type as keyof typeof messages] ?? messages.GENERIC
    );
    return;
  }
}

export const checkIfTokenError = <R extends Record<any, any> | string | number>(
  response: R
): R | TokenError => {
  if (typeof response === "string" || typeof response === "number") {
    return response;
  }
  if (response?.key === enums.API_RESPONSE_KEYS.TOKEN_ERROR) {
    throw new TokenError(response?.type, response?.error);
  } else {
    return response;
  }
};
