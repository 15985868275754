/* deepscan-disable UNUSED_VAR_ASSIGN */
import type { REGISTRATION_ENTITY } from "~/api/interfaces";
import { entityConfigSchema } from "~/api/interfaces";
import { zodInitialValue } from "~/utils/zodIntialValue";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { merge } from "lodash";

export type EntityConfigState = REGISTRATION_ENTITY;

export const initialState = zodInitialValue(entityConfigSchema);

export const slice = createSlice({
  name: "entity-config",
  initialState,
  reducers: {
    setIsPhoneNumberChange(state) {
      const nextState = merge(
        state.postInitialRegistrationFlowAvailability,
        initialState.postInitialRegistrationFlowAvailability
      );

      state.postInitialRegistrationFlowAvailability = nextState;
    },
    setEntityConfig(state, action: PayloadAction<EntityConfigState>) {
      state = merge(state, action.payload);
    },
    setFaceCaptureUnsupported(state) {
      if (state.inviteOnlyFieldEnablement?.faceCapture) {
        state.inviteOnlyFieldEnablement.faceCapture = false;
      }
    },
  },
});

export const { reducer } = slice;

export const { setEntityConfig } = slice.actions;

// PUBLIC METHODS
/**
 * TODO - check impact of this, because we set the context state as well as the slice machine,
 * so essentially this is a duplication just to avoid any initial interface issues, as well as
 * allowing changes via the dispatcher.
 */
export const entityConfig = {
  ...slice.actions,
};
